import {
  DealDispatcher,
  DealDispatcherPayload,
  DealVoteAction,
  Icon,
  VoteKind,
} from '@pelando/components';
import { useTranslation } from '@/presentation/hooks/useTranslation';

import { useCallback, useState } from 'react';
import { getDeal } from '@/infra/api/deals/endpoints';
import { useApiCache } from '@/presentation/hooks/useApiCache';
import { DefaultTextStore } from '@/presentation/components/DealHeaderMobile/style';
import { RemoveVoteVariables } from '@/infra/api/votes/types/RemoveVote';
import { removeVote, vote } from '@/infra/api/votes/endpoints';

import { OfferOptions } from '@/domain/entities/Offer';
import useMutationActionHandler from '@/presentation/components/Feed/FeedCard/hooks/useMutationActionHandler';
import { useCurrentUserCache } from '@/presentation/hooks/useCurrentUserCache';
import { HexagonCheck } from '@pelando/fontawesome/icons';
import {
  CardImage,
  Container,
  DealStampStyled,
  StoreLabel,
  Title,
  VoteAndStoreWrapper,
  VoteButtonStyled,
} from './style';

export type VotingCardProps = {
  onClickContinue: () => void;
};

export default function VotingCard({ onClickContinue }: VotingCardProps) {
  const { t } = useTranslation('deal', 'coupons');
  const { loggedUser: currentUser } = useCurrentUserCache();
  const isEmailConfirmed = currentUser?.emailConfirmed;

  const deal = useApiCache(getDeal.cache$);

  const { id, title, image, store } = deal || {};

  const hasStore = store?.name && store?.slug;

  const temperature = deal?.temperature || 0;

  const [temperatureFake, setTemperatureFake] = useState(temperature);

  const [voteInfo, setVoteInfo] = useState({
    voted: false,
    kind: VoteKind.INCREASE,
  });

  const handleVote = async ({
    id,
    voteKind,
    options,
  }: {
    id: string;
    voteKind: VoteKind;
    options?: OfferOptions;
  }) => {
    vote.requestAsPromise({
      id,
      voteKind,
      options,
    });
  };

  const handleRemoveVote = useCallback(
    (variables: RemoveVoteVariables) => removeVote.requestAsPromise(variables),
    []
  );
  const mutationHandler = useMutationActionHandler({
    onRemoveVote: handleRemoveVote,
    onVote: handleVote,
  });

  const handleDispatch: DealDispatcher = async ({ type, payload }) => {
    const { voteKind } = payload as DealDispatcherPayload<DealVoteAction>;
    const increaseDeal = 6;
    const decreaseDeal = -2;
    const voteTemperature =
      voteKind === VoteKind.INCREASE ? increaseDeal : decreaseDeal;

    if (!isEmailConfirmed) {
      setVoteInfo({
        voted: true,
        kind: voteKind,
      });
      setTemperatureFake(Math.round(temperatureFake + voteTemperature));
    } else {
      mutationHandler(
        type as DealVoteAction,
        payload as unknown as DealDispatcherPayload<DealVoteAction>
      );
      setVoteInfo({
        voted: true,
        kind: voteKind,
      });
      setTemperatureFake(Math.round(temperatureFake + voteTemperature));
    }
    setTimeout(() => {
      onClickContinue();
    }, 1000);
  };

  return (
    <Container>
      <CardImage
        key={image?.id}
        src={image?.url || ''}
        alt={title}
        errorImage={store?.image.url || ''}
        fetchPriority="high"
      />
      <VoteAndStoreWrapper>
        <VoteButtonStyled
          id={id || ''}
          temperature={Math.round(temperatureFake)}
          dispatch={handleDispatch}
          voteInfo={voteInfo}
        />
        <div>
          {hasStore ? (
            <DefaultTextStore>
              Vendido por
              <StoreLabel>
                {store?.name} <Icon icon={HexagonCheck} />
              </StoreLabel>
            </DefaultTextStore>
          ) : (
            <DefaultTextStore>
              {t('deal-information-section-default-text-store')}
            </DefaultTextStore>
          )}
        </div>
      </VoteAndStoreWrapper>
      <Title>{title}</Title>
      <DealStampStyled deal={deal} />
    </Container>
  );
}
